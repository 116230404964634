import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
  };

  return (
    <div className='center-vert'> 
      <div className='centre-col'>
        <p className='title'>
          Dual N Back
        </p>
        <div className="button-container">
          <div className="menu">
            <button className="menu-button" onClick={() => handleMenuClick('home')}>Play</button>
          </div>
          <div className="menu">
            <button className="menu-button" onClick={() => handleMenuClick('info')}>Information</button>
          </div>
          <div className="menu">
            <button className="menu-button" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Home;