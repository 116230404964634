import './Panel.css';

function Panel({ features, toggleFeature }) {
  const renderToggleButton = (feature) => {
    return (
      <button className="menu-items-button" onClick={() => toggleFeature(feature)}>
        {features[feature] ? 'On' : 'Off'}
      </button>
    );
  };

  return (
    <div className="panel-background">
      <div className="sizing-box">
        <table>
          <thead>
            <tr>
              <th className="panel-title" colspan="2">Parameter Selection</th>  {/* Ensure the header spans both columns */}
            </tr>
          </thead>
          <tbody>
            <tr className='panel-line'>
              <td><span className="menu-items-text">Colours</span></td>
              <td>{renderToggleButton('color')}</td>
            </tr>
            <tr className='panel-line'>
              <td><span className="menu-items-text">Shapes</span></td>
              <td>{renderToggleButton('shape')}</td>
            </tr>
            <tr className='panel-line'>
              <td><span className="menu-items-text">Audio</span></td>
              <td>{renderToggleButton('audio')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Panel;

