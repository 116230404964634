import React, { useState, useEffect } from 'react';
import './Info.css';
import { useNavigate, Link } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';
//import About from './About';
//import Donate from './Donate';

function Info() {
  //const navigate = useNavigate();
  const currentYear = new Date().getFullYear();


  const navigate = useNavigate();
  const handleMenuClick = (componentName) => {
      navigate('/' + componentName);
    };

    const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
      setIsMobileMode(mobile);
    }, []);
  
    // You can customize the mobile layout or desktop layout here
    if (isMobileMode) {
    
  return (
    <div className="app-container-mobile">
      <div className="top-bar-mobile">
        <button className="title-button-mobile" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu-mobile">
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
      <div className="info-content-mobile">
        <div className="site-container-mobile">
          <div className="info-container-mobile">
            <p className="info-title-mobile">
              Information
            </p>
            <p className="info-text-mobile">
                I first heard about Dual N Back when reading an article by <a className="info-text-link-mobile" href="https://gwern.net/dnb-faq" target="_blank" rel="noopener noreferrer">Gwern</a>. I found it very interesting and sought to try it myself, however I found it hard to find a website that was free and high quality. Around this time, I was conveniently searching for a coding challenge that I could sink my teeth into, and that is what led me to build the site.
            </p>
            <p className="info-text-mobile">
              Overall I am pleased with the outcome. It was an enjoyable project that I hope will be of use to people going forward. I plan on working on more projects in the future and you can follow my work via my <a className="info-text-link-mobile" href="https://anthonyduncalf.substack.com/" target="_blank" rel="noopener noreferrer">substack</a> or on my <a className="info-text-link-mobile" href="https://www.anthonyduncalf.dev/" target="_blank" rel="noopener noreferrer">website</a>. If you would like to support me with my work, you can make a donation <Link className="info-text-link-mobile" to="/donate">here</Link>.
            </p>
            <p className="info-text-mobile">
              For instructions on <button className="how-to-play-button-mobile" onClick={() => handleMenuClick('about')}>How to Play</button> Dual N Back, click the button. Enjoy!
            </p>

            <p className="bottom-text-mobile">
              Return to <button className="how-to-play-button-mobile" onClick={() => handleMenuClick('')}>Home Screen</button>
            </p>
          </div>

      <footer class="site-footer">
          <div class="footer-content">
              <p>© {currentYear} Anthony Duncalf. All rights reserved.</p>
              <p>Visit me at <a href="https://anthonyduncalf.dev" target="_blank">anthonyduncalf.dev</a>.</p>
          </div>
      </footer>
      </div>
    </div>
  </div>
  );
} else {
    return (
      <div className="app-container">
        <div className="top-bar">
          <button className="title-button" onClick={() => handleMenuClick('home')}>Dual N Back</button>
            <div className="menu">
              <button className="menu-buttons" onClick={() => handleMenuClick('home')}>Play</button>
              <button className="menu-buttons" onClick={() => handleMenuClick('about')}>About</button>
              <button className="menu-buttons" onClick={() => handleMenuClick('donate')}>Donate</button>
            </div>
          </div>
        <div className="content">
          <div className="site-container">
            <div className="info-container">
              <p className="info-title">
                Information
              </p>
              <p className="info-text">
                  I first heard about Dual N Back when reading an article by <a className="info-text-link" href="https://gwern.net/dnb-faq" target="_blank" rel="noopener noreferrer">Gwern</a>. I found it very interesting and sought to try it myself, however I found it hard to find a website that was free and high quality. Around this time, I was conveniently searching for a coding challenge that I could sink my teeth into, and that is what led me to build the site.
              </p>
              <p className="info-text">
                Overall I am pleased with the outcome. It was an enjoyable project that I hope will be of use to people going forward. I plan on working on more projects in the future and you can follow my work via my <a className="info-text-link" href="https://anthonyduncalf.substack.com/" target="_blank" rel="noopener noreferrer">substack</a> or on my <a className="info-text-link" href="https://www.anthonyduncalf.dev/" target="_blank" rel="noopener noreferrer">website</a>. If you would like to support me with my work, you can make a donation <Link className="info-text-link" to="/donate">here</Link>.
              </p>
              <p>
                For instructions on <button className="how-to-play-button" onClick={() => handleMenuClick('about')}>How to Play</button> Dual N Back, click the button. Enjoy!
              </p>
  
              <p className="bottom-text">
                Return to <button className="how-to-play-button" onClick={() => handleMenuClick('')}>Home Screen</button>
              </p>
            </div>
  
        <footer class="site-footer">
            <div class="footer-content">
                <p>© {currentYear} Anthony Duncalf. All rights reserved.</p>
                <p>Visit me at <a href="https://anthonyduncalf.dev" target="_blank">anthonyduncalf.dev</a>.</p>
            </div>
        </footer>
        </div>
      </div>
    </div>
    )
  }
}


export default Info;
 