import React, { useState, useEffect } from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
import Donate from './Donate';

function About({ selectedComponent }) {
  const navigate = useNavigate();
  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
  };
  
  const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
      setIsMobileMode(mobile);
    }, []);
  
    // You can customize the mobile layout or desktop layout here
    if (isMobileMode) {

  return (
    <div className="app-container-mobile">
      <div className="top-bar-mobile">
        <button className="title-button-mobile" onClick={() => handleMenuClick('home')}>Dual N Back</button>
        <div className="menu-mobile">
          <button className="menu-buttons-mobile" onClick={() => handleMenuClick('home')}>Play</button>
          <button className="menu-buttons-mobile" onClick={() => handleMenuClick('about')}>About</button>
          <button className="menu-buttons-mobile" onClick={() => handleMenuClick('donate')}>Donate</button>
        </div>
      </div>
      <div className='page-content-mobile'>
        <div className="content-title-mobile">
          <p className='what-is-it-mobile'>What is Dual N Back?</p>
        </div>
        <div className="content-about-mobile">
          <p className="about-text-mobile">Dual N Back is a cognitive training exercise aimed at improving working memory and fluid intelligence. The game involves the presentation of a sequence of stimuli (usually visual or auditory, or both) and the player must indicate when the current stimulus matches the one from "N" steps earlier in the sequence.</p>
        </div>  
        <div className="content-title-mobile">
          <p className="about-text-mobile">How to play</p>
        </div>
        <div className="how-to-play-mobile">
            <p className="bulletpoints">1. Select the 'N' back that you would like to play.</p>
            <p className="bulletpoints">2. Select the types of stimuli want to be in the game.</p>
            <p className="bulletpoints">3. Set the length of play.</p>
            <div className="click-container-mobile">
              <p className="bulletpoints">4. Click  </p>
              <button className="other-button-mobile" onClick={() => handleMenuClick('home')}>Play</button>
            </div>
            <p className="bulletpoints">5. Once completed, the test will tell you how you did.</p>
            <p className="about-text-mobile">
              For more information <button className="other-button-mobile" onClick={() => handleMenuClick('info')}>Click Here</button>
            </p>
        </div>  
      </div>
    </div>
    
  );
} else {
    return (
      <div className="app-container">
        <div className="top-bar">
          <button className="title-button" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu">
            <button className="menu-buttons" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
        <div className='page-content'>
          <div className="content-title">
            <p className='what-is-it'>What is Dual N Back?</p>
          </div>
          <div className="about-page-content">
            <p>Dual N Back is a cognitive training exercise aimed at improving working memory and fluid intelligence. The game involves the presentation of a sequence of stimuli (usually visual or auditory, or both) and the player must indicate when the current stimulus matches the one from "N" steps earlier in the sequence.</p>
          </div>  
          <div className="content-title">
            <p>How to play</p>
          </div>
          <div className="how-to-play">
            
              <p>1. Select the 'N' back that you would like to play.</p>
              <p>2. Select the types of stimuli want to be in the game.</p>
              <p>3. Set the length of play.</p>
              <div className="click-container">
                <p className="click-text">4. Click  </p>
                <button className="other-button" onClick={() => handleMenuClick('home')}>Play</button>
              </div>
              <p>5. Once completed, the test will tell you how you did.</p>
            
              <p className="bottom-text-info">
                For more information <button className="other-button" onClick={() => handleMenuClick('info')}>Click Here</button>
              </p>
                
              
          </div>  
          
        </div>
      </div>
      
    );
  }
}

export default About;