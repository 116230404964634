import React, { useState, useEffect } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import DualNBack from './DualNBack';

function App() {
  const navigate = useNavigate();
  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
  };
  
  const [isMobileMode, setIsMobileMode] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
    setIsMobileMode(mobile);
  }, []);

  // You can customize the mobile layout or desktop layout here
  if (isMobileMode) {
    return (
      <div className="app-container-mobile"> 
        <div className="top-bar-mobile">
          <button className="title-button-mobile" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu-mobile">
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
        <div className="content-mobile">
          <DualNBack />
        </div>
      </div>
    );
  } else {
    return (
      <div className="app-container">
        <div className="top-bar">
          <button className="title-button" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu">
            <button className="menu-buttons" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
        <div className="content">
          <DualNBack />
        </div>
      </div>
    );
  }
}

export default App;
