import React, { useState, useEffect, useRef } from 'react';
import './DualNBack.css'; // Ensure CSS is set up correctly
import Counter from './Counter';
import CounterMobile from './CounterMobile';
import Panel from './Panel';

const DualNBack = () => {
    // Define colors and shapes before using them
    const colors = ['#FF0000', '#0000FF', '#008000', '#FFFF00']; // Red, Blue, Green, Yellow
    const shapes = ['square', 'circle', 'triangle', 'star']; // Shape options
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

    // Now we can safely use colors and shapes in state initialization
    const [activeIndex, setActiveIndex] = useState(null);
    const [running, setRunning] = useState(false);
    const [roundCount, setRoundCount] = useState(0);
    const [maxRounds, setMaxRounds] = useState(100);
    const [gameCompleted, setGameCompleted] = useState(false);
    const [features, setFeatures] = useState({
        position: true,
        shape: true,
        color: true,
        audio: true
    });
    const audioRefs = useRef(alphabet.map(letter => new Audio(`audio/${letter}.mp3`)));
    const initialColor = colors[Math.floor(Math.random() * colors.length)];
    const [activeColor, setActiveColor] = useState(initialColor);
    const [activeShape, setActiveShape] = useState('Square');

    const [count, setCount] = useState(2); // Manage count here
    
    const [performance, setPerformance] = useState({
        position: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
        color: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
        shape: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
        audio: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
        noStimulus: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 }
    });

    const [userResponses, setUserResponses] = useState({
        position: false,
        color: false,
        shape: false,
        audio: false
    });

    const handleIncrement = () => {
        setCount(prevCount => (prevCount < 99 ? prevCount + 1 : prevCount));
    };
    
    const handleDecrement = () => {
        setCount(prevCount => (prevCount > 1 ? prevCount - 1 : prevCount));
    };
    
    const [history, setHistory] = useState([]); // History of past rounds

    const userResponsesRef = useRef(userResponses);

    useEffect(() => {
        userResponsesRef.current = userResponses;
    }, [userResponses]); // Update the ref whenever userResponses changes

    const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
        setIsMobileMode(mobile);
        console.log(mobile);
    }, []);

    const [isScreen, setIsScreen] = useState();

    const setScreen = () => {
        if (isScreen) {
            setIsScreen(false);
        } else {
            setIsScreen(true);
        }
    };

    useEffect(() => {
        let interval = null;
    
        if (running && roundCount < maxRounds) {
            interval = setInterval(() => {
                setRoundCount(prevRoundCount => prevRoundCount + 1);
                setCount(count)
                const nextActiveIndex = Math.floor(Math.random() * 9);
                const nextActiveColor = features.color ? colors[Math.floor(Math.random() * colors.length)] : 'rgb(228, 225, 225)';
                const randomIndex = Math.floor(Math.random() * alphabet.length);
                const nextShape = features.shape ? shapes[Math.floor(Math.random() * shapes.length)] : 'square';

                setActiveIndex(nextActiveIndex);
                setActiveColor(nextActiveColor);
                setActiveShape(nextShape);

                if (features.audio) { // Only play audio if the audio feature is enabled
                    audioRefs.current[randomIndex].play().catch(e => console.error("Error playing audio:", e));
                }
                
                const newHistoryEntry = {
                    position: nextActiveIndex,
                    color: nextActiveColor,
                    shape: nextShape,
                    audio: alphabet[randomIndex],
                };

                setHistory([...history, newHistoryEntry].slice(-count)); // Keep only the last 'count' entries

                setTimeout(() => {
                    //console.log(userResponsesRef.current); // Accessing latest state via ref
                    if (history.length >= count) {
                        const pastRound = history[history.length - count];
                        checkMatch(newHistoryEntry, pastRound);
                    }

                    setUserResponses({ position: false, color: false, shape: false, audio: false });
                    setActiveIndex(null);
                },1900);
            }, 2000);
        } else {
            clearInterval(interval);
            if (roundCount === maxRounds && roundCount > 0 && !gameCompleted) {
                setTimeout(() => {
                    alert('Game complete!');
                    setRoundCount(0);
                    setRunning(false);
                    setGameCompleted(true);
                }, 1900);
            }
        }
    
        return () => clearInterval(interval);
    }, [running, roundCount, maxRounds, gameCompleted, features, history, count]);
    
    const checkMatch = (current, past) => {
        console.log(current.audio,past.audio)
        console.log(current.shape,past.shape)
        console.log(current.color,past.color)
        console.log(current.position,past.position)
        const results = {
            positionMatch: current.position === past.position,
            colorMatch: current.color === past.color,
            shapeMatch: current.shape === past.shape,
            audioMatch: current.audio === past.audio
        };
        
        // Iterate over each feature to update performance based on user response and game results
        Object.entries(results).forEach(([key, match]) => {
            const feature = key.replace('Match', '');
    
            // Only process updates for features that are enabled
            if (features[feature]) {
                const userResponse = userResponsesRef.current[feature];
    
                // Check if either a match or a user response exists to avoid updating when both are false
                if (match || userResponse) {
                    // Calculate correctness based on game results and user responses
                    const correct = match && userResponse;
                    const incorrect = (!match && userResponse) || (match && !userResponse);
    
                    // Update performance metrics only for enabled features and when either is true
                    setPerformance(perf => ({
                        ...perf,
                        [feature]: {
                            correct: perf[feature].correct + (correct ? 1 : 0),
                            incorrect: perf[feature].incorrect + (incorrect ? 1 : 0),
                        }
                    }));
                    //console.log(`Feature: ${feature}, Enabled: ${features[feature]}, Match: ${match}, User Response: ${userResponse}, Correct: ${correct}, Incorrect: ${incorrect}`);
                } else {
                    // Optionally log when skipped due to both match and user response being false
                    //console.log(`Feature: ${feature}, Match and User Response both false, no update performed.`);
                }
            } else {
                // Log when a feature is disabled and not processed
                //console.log(`Feature: ${feature} is disabled and was not checked.`);
            }
        });
    
        // Special case for no stimulus, only consider enabled features
        const allFalse = Object.keys(results).every(key => {
            const feature = key.replace('Match', '');
            return !features[feature] || !results[key];  // Ignore disabled features
        });
    
        const anyResponse = Object.keys(userResponsesRef.current).some(key => {
            return features[key] && userResponsesRef.current[key];
        });
    
        // Update no stimulus metrics considering enabled features and responses
        setPerformance(perf => ({
            ...perf,
            noStimulus: {
                correct: perf.noStimulus.correct + (allFalse && !anyResponse ? 1 : 0),
                incorrect: perf.noStimulus.incorrect + (allFalse && anyResponse ? 1 : 0),
            }
        }));
    };
    
    const [gameStarted, setGameStarted] = useState(false);

    const handleClear = () => {
        setRunning(false);
        setActiveIndex(null);
        setRoundCount(0);
        setGameCompleted(false);
        setHistory([]);
        setGameStarted(false);
    };

    const handleMaxRoundsChange = (event) => {
        const value = Number(event.target.value);
        setMaxRounds(value);
    };

    const toggleFeature = (feature) => {
        setFeatures(prevFeatures => ({
            ...prevFeatures,
            [feature]: !prevFeatures[feature]
        }));
    };

    const handleUserInput = (inputType) => {
        setUserResponses(prevResponses => ({
            ...prevResponses,
            [inputType]: true  // Set only the specified input type to true
        }));
    };
    
    //console.log(userResponses)

    const handleStart = () => {
        // Reset performance metrics
        setPerformance({
            position: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
            color: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
            shape: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
            audio: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 },
            noStimulus: { correct: 0, incorrect: 0, trials: 0, accuracy: 0 }
        });
    
        // Additional logic to start the game
        setRunning(true);
        setRoundCount(0); // Assuming you want to reset the round count as well
        setGameCompleted(false);
        setGameStarted(true)
    };

    const PerformanceTable = ({features, performance}) => {

        const calculateAccuracy = (correct, incorrect) => {
            const total = correct + incorrect;
            return total > 0 ? ((correct / total) * 100).toFixed(0) + "%" : "0%";
        };

        const data = {
            position: {
                ...performance.position,
                accuracy: calculateAccuracy(performance.position.correct, performance.position.incorrect),
            },
            color: {
                ...performance.color,
                accuracy: calculateAccuracy(performance.color.correct, performance.color.incorrect),
            },
            shape: {
                ...performance.shape,
                accuracy: calculateAccuracy(performance.shape.correct, performance.shape.incorrect),
            },
            audio: {
                ...performance.audio,
                accuracy: calculateAccuracy(performance.audio.correct, performance.audio.incorrect),
            },
            noStimulus: {
                ...performance.noStimulus,
                accuracy: calculateAccuracy(performance.noStimulus.correct, performance.noStimulus.incorrect),
            },
            total: {
                correct: performance.position.correct + performance.color.correct + performance.shape.correct + performance.audio.correct + performance.noStimulus.correct,
                incorrect: performance.position.incorrect + performance.color.incorrect + performance.shape.incorrect + performance.audio.incorrect + performance.noStimulus.incorrect,
                accuracy: calculateAccuracy(
                    performance.position.correct + performance.color.correct + performance.shape.correct + performance.audio.correct + performance.noStimulus.correct,
                    performance.position.incorrect + performance.color.incorrect + performance.shape.incorrect + performance.audio.incorrect + performance.noStimulus.incorrect
                )
            }
        };
    
        return (
            <table className="performance-table">
                <thead>
                    <tr>
                        <th className="perform-th">{count}-Back</th>
                        <th className="perform-th">Correct</th>
                        <th className="perform-th">Incorrect</th>
                        <th className="perform-th">Accuracy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="perform-td">Position</td>
                        <td className="perform-td">{data.position.correct}</td>
                        <td className="perform-td">{data.position.incorrect}</td>
                        <td className="perform-td">{data.position.accuracy}</td>
                    </tr>
                    {features.color && <tr>
                        <td className="perform-td">Colour</td>
                        <td className="perform-td">{data.color.correct}</td>
                        <td className="perform-td">{data.color.incorrect}</td>
                        <td className="perform-td">{data.color.accuracy}</td>
                    </tr>}
                    {features.shape && <tr>
                        <td className="perform-td">Shape</td>
                        <td className="perform-td">{data.shape.correct}</td>
                        <td className="perform-td">{data.shape.incorrect}</td>
                        <td className="perform-td">{data.shape.accuracy}</td>
                    </tr>}
                    {features.audio && <tr>
                        <td className="perform-td">Audio</td>
                        <td className="perform-td">{data.audio.correct}</td>
                        <td className="perform-td">{data.audio.incorrect}</td>
                        <td className="perform-td">{data.audio.accuracy}</td>
                    </tr>}
                    <tr>
                        <td className="perform-td">No Stimulus</td>
                        <td className="perform-td">{data.noStimulus.correct}</td>
                        <td className="perform-td">{data.noStimulus.incorrect}</td>
                        <td className="perform-td">{data.noStimulus.accuracy}</td>
                    </tr>
                    <tr>
                        <td className="perform-td">Total</td>
                        <td className="perform-td">{data.total.correct}</td>
                        <td className="perform-td">{data.total.incorrect}</td>
                        <td className="perform-td">{data.total.accuracy}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const PerformanceTableMobile = ({features, performance}) => {

        const calculateAccuracy = (correct, incorrect) => {
            const total = correct + incorrect;
            return total > 0 ? ((correct / total) * 100).toFixed(0) + "%" : "0%";
        };

        const data = {
            position: {
                ...performance.position,
                accuracy: calculateAccuracy(performance.position.correct, performance.position.incorrect),
            },
            color: {
                ...performance.color,
                accuracy: calculateAccuracy(performance.color.correct, performance.color.incorrect),
            },
            shape: {
                ...performance.shape,
                accuracy: calculateAccuracy(performance.shape.correct, performance.shape.incorrect),
            },
            audio: {
                ...performance.audio,
                accuracy: calculateAccuracy(performance.audio.correct, performance.audio.incorrect),
            },
            noStimulus: {
                ...performance.noStimulus,
                accuracy: calculateAccuracy(performance.noStimulus.correct, performance.noStimulus.incorrect),
            },
            total: {
                correct: performance.position.correct + performance.color.correct + performance.shape.correct + performance.audio.correct + performance.noStimulus.correct,
                incorrect: performance.position.incorrect + performance.color.incorrect + performance.shape.incorrect + performance.audio.incorrect + performance.noStimulus.incorrect,
                accuracy: calculateAccuracy(
                    performance.position.correct + performance.color.correct + performance.shape.correct + performance.audio.correct + performance.noStimulus.correct,
                    performance.position.incorrect + performance.color.incorrect + performance.shape.incorrect + performance.audio.incorrect + performance.noStimulus.incorrect
                )
            }
        };
    
        return (
            <table className="performance-table-mobile">
                <thead>
                    <tr>
                        <th className="perform-th-mobile">{count}-Back</th>
                        <th className="perform-th-mobile">Correct</th>
                        <th className="perform-th-mobile">Incorrect</th>
                        <th className="perform-th-mobile">Accuracy</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="perform-td-mobile">Position</td>
                        <td className="perform-td-mobile">{data.position.correct}</td>
                        <td className="perform-td-mobile">{data.position.incorrect}</td>
                        <td className="perform-td-mobile">{data.position.accuracy}</td>
                    </tr>
                    {features.color && <tr>
                        <td className="perform-td-mobile">Colour</td>
                        <td className="perform-td-mobile">{data.color.correct}</td>
                        <td className="perform-td-mobile">{data.color.incorrect}</td>
                        <td className="perform-td-mobile">{data.color.accuracy}</td>
                    </tr>}
                    {features.shape && <tr>
                        <td className="perform-td-mobile">Shape</td>
                        <td className="perform-td-mobile">{data.shape.correct}</td>
                        <td className="perform-td-mobile">{data.shape.incorrect}</td>
                        <td className="perform-td-mobile">{data.shape.accuracy}</td>
                    </tr>}
                    {features.audio && <tr>
                        <td className="perform-td-mobile">Audio</td>
                        <td className="perform-td-mobile">{data.audio.correct}</td>
                        <td className="perform-td-mobile">{data.audio.incorrect}</td>
                        <td className="perform-td-mobile">{data.audio.accuracy}</td>
                    </tr>}
                    <tr>
                        <td className="perform-td-mobile">No Stimulus</td>
                        <td className="perform-td-mobile">{data.noStimulus.correct}</td>
                        <td className="perform-td-mobile">{data.noStimulus.incorrect}</td>
                        <td className="perform-td-mobile">{data.noStimulus.accuracy}</td>
                    </tr>
                    <tr>
                        <td className="perform-td-mobile">Total</td>
                        <td className="perform-td-mobile">{data.total.correct}</td>
                        <td className="perform-td-mobile">{data.total.incorrect}</td>
                        <td className="perform-td-mobile">{data.total.accuracy}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const intervalRef = useRef(null);
    
    const handlePauseResume = () => {
        if (running) {
            clearInterval(intervalRef.current);  // Stop the interval if running
        } else {
            startGame();  // Start or resume the game if not running
        }
        setRunning(!running);  // Toggle the running state
    };

    const startGame = () => {
        intervalRef.current = setInterval(() => {
            // Your game logic here
            console.log("Game is running");
            // Update game state, check conditions, etc.
        }, 1000);  // Interval set to 1 second, adjust as needed
    };

    useEffect(() => {
        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalRef.current);
    }, []);

    
    return (
        <>
            {isMobileMode && (
                <>
                    {!isScreen ? (
                        <>
                            <div className="mobile-container">
                                <div className="round-input-container-mobile">
                                                <p className="round-label-mobile">Round: {roundCount} /</p>
                                                <input
                                                    className="round-limit-input-mobile"
                                                    type="number"
                                                    value={maxRounds}
                                                    onChange={handleMaxRoundsChange}
                                                    placeholder="100"
                                                    required
                                                    pattern="\d*"
                                                    maxLength="3"
                                                />
                                        </div>
                                <div className="button-panel-container">
                                    <div className='button-panel-mobile'>
                                        {!gameStarted && <button className="start-stop-mobile-screen-one" onClick={() => handleStart()}>Begin</button>}
                                        <button className="start-stop-mobile-screen-one" onClick={() => handlePauseResume()}>
                                            {running ? "Pause" : "Resume"}
                                        </button>
                                        {gameStarted && <button className="start-stop-mobile-screen-one" onClick={handleClear}>Reset</button>}
                                        <button className="start-stop-mobile-screen-one" onClick={setScreen}>Settings</button>
                                    </div>
                                </div>
                                <div className="grid-mobile">
                                    {Array.from({ length: 9 }).map((_, index) => (
                                        <div key={index} className="square-mobile" style={{ backgroundColor: index === activeIndex ? '#252424' : 'transparent' }}>
                                            {activeIndex === index && (
                                                <div 
                                                    className={`shape-${activeShape.toLowerCase()}-mobile`}
                                                    style={activeShape.toLowerCase() === 'triangle' ? 
                                                        { borderColor: `transparent transparent ${activeColor} transparent` } : 
                                                        { backgroundColor: activeColor }
                                                    }
                                                ></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="center-answer-container-mobile"> 
                                <div className="answer-container-mobile">
                                    <button className="answer-buttons-mobile" onClick={() => handleUserInput('position')}>Position</button>
                                    {features.color && <button className="answer-buttons-mobile" onClick={() => handleUserInput('color')}>Colour</button>}
                                    {features.shape && <button className="answer-buttons-mobile" onClick={() => handleUserInput('shape')}>Shape</button>}
                                    {features.audio && <button className="answer-buttons-mobile" onClick={() => handleUserInput('audio')}>Auditory</button>}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mobile-container">
                                <p className="setting-text">Select the N number of turns you would like to attempt.</p>
                                <div className='top-row-mobile'>
                                    <div className="counter-center-container-mobile">  
                                        <CounterMobile
                                            count={count}
                                            onIncrement={handleIncrement}
                                            onDecrement={handleDecrement}
                                        />
                                    </div>
                                    <button className="start-stop-mobile-screen-two" onClick={setScreen}>Return to Game</button>
                                </div>
                                <div className="table-center-container">
                                    <PerformanceTableMobile className="performance-table-mobile" features={features} performance={performance} />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
    
            {!isMobileMode && (
                <div className="all-content">
                    <div className="content">
                        <div className="counter-container">
                            <div>
                                <div className="counter-center-container">  
                                    <Counter
                                        count={count}
                                        onIncrement={handleIncrement}
                                        onDecrement={handleDecrement}
                                    />
                                </div>
                                <div className="controls">
                                    <div className="control-panel">
                                        {!gameStarted && <button className="start-stop" onClick={() => handleStart()}>Begin</button>}
                                        <button className="start-stop" onClick={() => handlePauseResume()}>
                                            {running ? "Pause" : "Resume"}
                                        </button>
                                        {gameStarted && <button className="start-stop" onClick={handleClear}>Reset</button>}
                                    </div>
                                    <div className="round-input-container">
                                        <p className="round-label">Round: {roundCount} /</p>
                                        <input
                                            className="round-limit-input"
                                            type="number"
                                            value={maxRounds}
                                            onChange={handleMaxRoundsChange}
                                            placeholder="100"
                                            required
                                            pattern="\d*"
                                            maxLength="3"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="panel-center-container">
                                <Panel features={features} toggleFeature={toggleFeature} />
                            </div>
                        </div> 
    
                        <div className="center-container">
                            <div className="grid">
                                {Array.from({ length: 9 }).map((_, index) => (
                                    <div key={index} className="square" style={{ backgroundColor: index === activeIndex ? '#252424' : 'transparent' }}>
                                        {activeIndex === index && (
                                            <div 
                                                className={`shape-${activeShape.toLowerCase()}`}
                                                style={activeShape.toLowerCase() === 'triangle' ? 
                                                    { borderColor: `transparent transparent ${activeColor} transparent` } : 
                                                    { backgroundColor: activeColor }
                                                }
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="center-answer-container"> 
                                <div className="answer-container">
                                    <button className="answer-buttons" onClick={() => handleUserInput('position')}>Position</button>
                                    {features.color && <button className="answer-buttons" onClick={() => handleUserInput('color')}>Colour</button>}
                                    {features.shape && <button className="answer-buttons" onClick={() => handleUserInput('shape')}>Shape</button>}
                                    {features.audio && <button className="answer-buttons" onClick={() => handleUserInput('audio')}>Auditory</button>}
                                </div>
                            </div>   
                        </div>
                    </div>
                    
                    <div className="bottom-row-alignment">
                        <div className="table-center-container">
                            <PerformanceTable className="performance-table" features={features} performance={performance} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
    
};

export default DualNBack;
 