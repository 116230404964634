import React, { useState, useEffect } from 'react';
import './Donate.css';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeDonationForm from './StripeDonationForm'; // Assume you have this component

function App({ selectedComponent }) {
  const navigate = useNavigate();
  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
  };

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
      setIsMobileMode(mobile);
    }, []);
  
    // You can customize the mobile layout or desktop layout here
    if (isMobileMode) {

  return (
      <div className="app-container-mobile">
        <div className="top-bar-mobile">
          <button className="title-button-mobile" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu-mobile">
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons-mobile" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
        <div className="content-donate-mobile">
          
          {/*<Elements stripe={stripePromise}>
            <StripeDonationForm />  
  </Elements>*/}
            
          <div className="wallet-container-container-mobile">
            <div className="wallet-container-mobile">
            <img className="wallet-address-mobile" src="/Assets/Btc.jpg" alt="Logo" />
            <img className="wallet-address-mobile" src="/Assets/Eth.jpg" alt="Logo" />
            <img className="wallet-address-mobile" src="/Assets/Doge.jpg" alt="Logo" />
            </div>
            <div>
            <p className="wallet-text-mobile">BTC Address:</p>
            <p className="wallet-text-mobile">bc1qqkvvghstgrffvrjm98qfl8nqfu347hapz5lf84</p>
            <p className="wallet-text-mobile">ETH Address:</p>
            <p className="wallet-text-mobile">0xFe9f79092b80533467566fe954FEF4DCA814cE41</p>
            <p className="wallet-text-mobile">DOGE Address:</p>
            <p className="wallet-text-mobile">D6XPiFgcgjW8WD6zZP7qvEsgaa6bRhzxXP</p>
            </div>
          </div>
        </div>
      </div>
    
  );} else{
    return (
      <div className="app-container">
        <div className="top-bar">
          <button className="title-button" onClick={() => handleMenuClick('home')}>Dual N Back</button>
          <div className="menu">
            <button className="menu-buttons" onClick={() => handleMenuClick('home')}>Play</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('about')}>About</button>
            <button className="menu-buttons" onClick={() => handleMenuClick('donate')}>Donate</button>
          </div>
        </div>
        <div className="content-donate">
          
          {/*<Elements stripe={stripePromise}>
            <StripeDonationForm />  
  </Elements>*/}
            
          <div className="wallet-container-container">
            <div className="wallet-container">
            <img className="wallet-address" src="/Assets/Btc.jpg" alt="Logo" />
            <img className="wallet-address" src="/Assets/Eth.jpg" alt="Logo" />
            <img className="wallet-address" src="/Assets/Doge.jpg" alt="Logo" />
            </div>
            <div>
            <p className="wallet-text">BTC Address: bc1qqkvvghstgrffvrjm98qfl8nqfu347hapz5lf84</p>
            <p className="wallet-text">ETH Address: 0xFe9f79092b80533467566fe954FEF4DCA814cE41</p>
            <p className="wallet-text">DOGE Address: D6XPiFgcgjW8WD6zZP7qvEsgaa6bRhzxXP</p>
            </div>
          </div>
        </div>
      </div>
    
  );
  }
}

export default App;
