import './Counter.css'; // Make sure to link your CSS file

const Counter = ({ count, onIncrement, onDecrement }) => {

    return (
        <div className="counter">
            <div className="counter-grid">
                <div className="triangle-up" onClick={onIncrement}></div>
                <div className="triangle-down" onClick={onDecrement}></div>
            </div>
            <div className="count-display">{count} - Back</div>
        </div>
    );
};

export default Counter;
 
