import './CounterMobile.css'; // Make sure to link your CSS file

const CounterMobile = ({ count, onIncrement, onDecrement }) => {

    return (
        <div className="counter-mobile">
            <div className="counter-grid-mobile">
                <div className="triangle-up-mobile" onClick={onIncrement}></div>
                <div className="triangle-down-mobile" onClick={onDecrement}></div>
            </div>
            <div className="count-display-mobile">{count} - Back</div>
        </div>
    );
};

export default CounterMobile;
 
